import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IToastProps } from 'pages/odogwu';

interface IModalStateProps {
	status: boolean;
	type: string;
	modalObj: object;
}
interface IInitialState {
	odogwuSoundState: boolean;
	odogwuAnimationState: boolean;
	odogwuProfile: object;
	toastArr: IToastProps[];
	isGameEnabled: boolean;
	modalState: IModalStateProps;
	hunterBalances: object;
	newHunter: boolean;
	hunterTour: boolean;
	hunterStory: boolean;
	gameStatus: boolean;
	isHunterDemoMode: boolean;
	hunterObject: object;
	bidAction: object;
	gameData: object;
	activeHunter: object;
	hunterCharacters: object;
	cashoutDetails: { [key: string]: string | number };
	shootPoint: string | number;
	joinHunterObj: object;
	isHunterIdle: boolean;
	showFloatingBanner: boolean;
	huntWinner: object;
	marketData: object;
	iceData: number;
}

export const initialState: IInitialState = {
	odogwuSoundState: false,
	hunterObject: {
		newHunter: true,
		sound: false,
	},
	odogwuAnimationState: true,
	odogwuProfile: {},
	toastArr: [],
	isGameEnabled: true,
	modalState: { status: false, type: '', modalObj: {} },
	hunterBalances: {},
	newHunter: false,
	hunterStory: false,
	hunterTour: false,
	gameStatus: true,
	isHunterDemoMode: true,
	bidAction: {},
	gameData: {},
	activeHunter: {},
	hunterCharacters: {},
	cashoutDetails: {},
	shootPoint: '',
	joinHunterObj: {},
	isHunterIdle: false,
	showFloatingBanner: false,
	huntWinner: {},
	marketData: {},
	iceData: 0,
};

export const odogwuSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		addOdogwuProfile: (state, action: PayloadAction<object>) => {
			state.odogwuProfile = action.payload;
		},
		toggleOdogwuSound: (state, action: PayloadAction<boolean>) => {
			state.odogwuSoundState = action.payload;
		},
		setHunterObject: (state, action: PayloadAction<object>) => {
			state.hunterObject = action.payload;
		},
		toggleOdogwuAnimantion: (state, action: PayloadAction<boolean>) => {
			state.odogwuAnimationState = action.payload;
		},
		addToast: (state, action: PayloadAction<IToastProps>) => {
			state.toastArr = [...state.toastArr, action.payload];
		},
		removeToast: (state, action: PayloadAction<string | number>) => {
			state.toastArr = state.toastArr.filter(
				(item: any) => item.id !== action.payload
			);
		},
		setIsGameEnabled: (state, action: PayloadAction<boolean>) => {
			state.isGameEnabled = action.payload;
		},
		changeModalState: (state, action: PayloadAction<IModalStateProps>) => {
			state.modalState = action.payload;
		},
		addHunterBalances: (state, action: PayloadAction<object>) => {
			state.hunterBalances = action.payload;
		},
		setNewHunter: (state, action: PayloadAction<boolean>) => {
			state.newHunter = action.payload;
		},
		setHunterTour: (state, action: PayloadAction<boolean>) => {
			state.hunterTour = action.payload;
		},
		setHunterStory: (state, action: PayloadAction<boolean>) => {
			state.hunterStory = action.payload;
		},
		setGameStatus: (state, action: PayloadAction<boolean>) => {
			state.gameStatus = action.payload;
		},
		setIsDemoMode: (state, action: PayloadAction<boolean>) => {
			state.isHunterDemoMode = action.payload;
		},
		setActionStatus: (state, action: PayloadAction<object>) => {
			state.bidAction = action.payload;
		},
		setGameData: (state, action: PayloadAction<object>) => {
			state.gameData = action.payload;
		},
		setActiveHunterData: (state, action: PayloadAction<object>) => {
			state.activeHunter = action.payload;
		},
		setHunterCharacters: (state, action: PayloadAction<object>) => {
			state.hunterCharacters = action.payload;
		},
		setCashoutDetails: (
			state,
			action: PayloadAction<{ [key: string]: string | number }>
		) => {
			state.cashoutDetails = action.payload;
		},
		setshootPoint: (state, action: PayloadAction<string | number>) => {
			state.shootPoint = action.payload;
		},
		setJoinHunterObj: (state, action: PayloadAction<object>) => {
			state.joinHunterObj = action.payload;
		},
		setIsHunterIdle: (state, action: PayloadAction<boolean>) => {
			state.isHunterIdle = action.payload;
		},
		setIsFloatingBanner: (state, action: PayloadAction<boolean>) => {
			state.showFloatingBanner = action.payload;
		},
		setHuntWinner: (state, action: PayloadAction<object>) => {
			state.huntWinner = action.payload;
		},
		setMarketData: (state, action: PayloadAction<object>) => {
			console.log('setting market data');
			state.marketData = action.payload;
		},
		setIceData: (state, action: PayloadAction<object>) => {
			console.log('setting ice data: ', Number(action.payload));
			state.iceData = Number(action.payload);
		},
	},
});

export const odogwuActions = odogwuSlice.actions;
export const odogwuReducer = odogwuSlice.reducer;
