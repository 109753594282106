import { apiService } from 'api/axios';
import { publicApiService } from 'api/publicApiService';
import { _getUser, _isAnEmptyObject } from 'utils';

export const getSpinBids = async () => {
	const response = await apiService().get('/member/game-center/spin/bids');
	return response;
};

export const getGameRevenue = async () => {
	const response = await apiService().get('/member/game-center/game/revenue');
	return response;
};

export const getJackpotBids = async () => {
	const response = await apiService().get(
		'/member/game-center/jackpots/raffle/bid'
	);
	return response;
};

export const gameCenterDataApi = async () => {
	const response = await publicApiService.get('/public/game-center/data');
	return response;
};

export const getPowerUpsApi = async () => {
	const response = await apiService().get(`/member/game-center/power-ups`);
	return response;
};

export const getSpinPowerUps = async () => {
	const response = await apiService({ gameKey: 'spin' }).get(
		`/member/game-center/power-ups?gameKey=spin`
	);
	return response;
};

export const getDailyLeaderboardData = async (userId: string) => {
	const response = await apiService({ publicMemberId: userId }).get(
		`/public/gems-economy/gems/leader-board/today?publicMemberId=${userId}`
	);
	return response;
};

export const getWeeklyLeaderboardData = async () => {
	const response = await apiService().get(
		`/public/gems-economy/draw/weekly/winners`
	);
	return response;
};

export const getDailyDrawData = async () => {
	const response = await apiService().get(
		`/public/gems-economy/draw/daily/winners`
	);
	return response;
};

export const getDailyLeaderboardYesterdayData = async (userId: string) => {
	const response = await apiService({ publicMemberId: userId }).get(
		`/public/gems-economy/gems/leader-board/yesterday?publicMemberId=${userId}`
	);
	return response;
};

export const getAllMarketProductDataApi = async (query?: string) => {
	const response = await publicApiService.get(`/public/products?${query}`);
	return response;
};

export const getProductCategoryData = async () => {
	const response = await publicApiService.get('/public/product-categories');
	return response;
};

export const getAllUserOrders = async (payload?: object, params?: string) => {
	const response = await apiService(payload).get(
		`/member/market-place/game-assets${params ? params : ''}`
	);
	return response;
};

export const getHunterShootoutTimeHistory = async () => {
	const response = await publicApiService.get('/public/hunter/rounds');
	return response;
};

export const getDailyMissionApi = async () => {
	const user = _getUser();
	if (!_isAnEmptyObject(user)) {
		const response = await apiService().get(
			`/member/game-center/missions/daily`
		);
		return response;
	} else {
		const response = await publicApiService.get(
			'/public/game-center/missions/daily'
		);
		return response;
	}
};

export const getDailyLeaderboardPrice = async () => {
	const response = await publicApiService.get(
		`/public/gems-economy/draw/daily/price`
	);

	return response;
};

// export const registerUserApi = async (data: object) => {
// 	const payload = { ...data };
// 	const response = await publicApiService.post({
// 		url: '/auth/registration/start',
// 		payload,
// 	});
// 	return response;
// };
export const applyIceCooling = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: `/member/game-center/ice/cooling`,
		payload,
	});

	return response;
};

export const getUserBackgroundApi = async (params?: object) => {
	const response = await apiService(params).get(
		'/member/game-center/backgrounds'
	);
	return response;
};

export const getAllUserBackgrounds = async (params?: object) => {
	const response = await apiService(params).get(
		'/member/game-center/backgrounds'
	);
	return response;
};

export const getUserSpinBackgrounds = async () => {
	const response = await apiService({ gameKey: 'spin' }).get(
		'/member/game-center/backgrounds?gameKey=spin'
	);
	return response;
};

export const setDefaultBackground = async (data?: object) => {
	const payload = { ...data };
	const response = await apiService(payload).patch({
		url: '/member/game-center/backgrounds/set-default',
		payload,
	});
	return response;
};

export const setDefaultHunterCharacter = async (data?: any) => {
	if (data.gameCharacterId === null || data.gameCharacterId === undefined) {
		delete data.gameCharacterId;
	}
	const payload = { ...data };
	const response = await apiService(payload).patch({
		url: '/member/game-center/characters/set-default',
		payload,
	});
	return response;
};

export const resetDefaultHunterCharacter = async (data?: object) => {
	const payload = { ...data };
	const response = await apiService(payload).patch({
		url: '/member/game-center/characters/re-set-default',
		payload,
	});
	return response;
};

export const getPurchasedHunterCharacters = async () => {
	const response = await apiService().get(`/member/game-center/characters`);
	return response;
};
